import { useEffect, useState } from "react";
import FullScreenSection from "../components/FullScreenSection";
import { Heading, Box, VStack, FormControl, FormLabel, Input, FormErrorMessage, Button } from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useSubmit from "../hooks/useAuthSubmit";
import { useAlertContext } from "../context/alertContext";
import { useGlobalData } from "../context/GlobalDataContext";

// const url = "http://192.168.4.35:8085/api/admin-login";

const AdminLogin = () => {
    const { serviceUrl } = useGlobalData();
    const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
    const [ windowHeight, setWwindowHeight ] = useState(window.innerHeight);
    const [ isMobile, setIsMobile ] = useState(false);

    const { isLoading, response, authSumbit } = useSubmit();
    const { onOpen } = useAlertContext();

    const url = serviceUrl + "/api/admin-login";

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWwindowHeight(window.innerHeight);
            setIsMobile(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, []);

    useEffect(() => {
        // console.log("Response: ", response);
        if (response != null) {
            onOpen(response.type, response.message);
            if (response.type === "success") {
                formik.resetForm();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    // console.log(windowWidth);
    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },

        onSubmit: (values, ) => {
            authSumbit(url, values);

        },

        validationSchema: Yup.object({
            userName: Yup.string().required("Required"),
            password: Yup.string()
                            // .min(8, "Must be at least 8 characters")
                            .required("required"),
        }),
    });

    return (
        <FullScreenSection
            py={10}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={7}
            backgroundColor={"lightgray"}
        >
            <VStack spacing={7} width={windowWidth} alignItems={"center"} p={3}>
                <Heading color={"gray"}>Login</Heading>
                <Box
                    width={isMobile ? windowWidth * 0.7 : windowWidth * 0.5}
                    height={windowHeight * 0.5}
                    backgroundColor={"RGBA(127, 163, 90, 0.2)"}
                    borderRadius={"10px"}
                    p={4}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <form 
                        style={{width:"100%", display:"flex", justifyContent: "center"}}
                        onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        
                    }}>
                    <VStack 
                        height={"70%"}
                        width={"70%"}
                        spacing={"30px"}
                        justifyContent={"center"}
                        align={"center"}
                        // backgroundColor={"lightcoral"}
                    >
                        <FormControl isInvalid = {formik.touched.userName && formik.errors.userName ? true : false}> 
                            <FormLabel htmlFor="un">User Name</FormLabel>
                            <Input 
                                id="un"
                                name="un"
                                {...formik.getFieldProps("userName")}
                            />
                            <FormErrorMessage>{formik.errors.userName}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid = {formik.touched.password && formik.errors.password ? true : false}> 
                            <FormLabel htmlFor="pw">Password</FormLabel>
                            <Input 
                                id="pw"
                                name="pw"
                                type="password"
                                autoComplete="password"
                                {...formik.getFieldProps("password")}
                            />
                            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                        </FormControl>
                        <Button 
                            type="submit" 
                            width={"full"}
                            height={"40px"}
                            isLoading = {isLoading}
                        >
                            Submit
                        </Button>

                    </VStack>
                    </form>
                    
                </Box>
            </VStack>
        </FullScreenSection>
    );

};

export default AdminLogin;