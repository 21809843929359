import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { GlobalDataProvider } from './context/GlobalDataContext';
import { AlertProvider } from './context/alertContext';
import { HomePage } from './pages/HomePage';
import { MobileMenuProvider } from './context/mobileMenuContext';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MySummary from './pages/MySummary';
import Footer from './components/Footer';
import ProjectPage from './pages/ProjectPage';
import AdminConsole from './pages/AdminConsole';
import Alert from './components/Alert';
import NotFound from './components/NotFound';
import { LoginProvider } from './context/loginContext';
import BlogsPage from './pages/BlogsPage';

function App() {
  return (
    <ChakraProvider>
      <GlobalDataProvider>
        <AlertProvider>
          {/* <MessageProvider> */}
          <MobileMenuProvider>
            <LoginProvider>
      <main>
        <Alert />
          <Header />          
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/summary" element={<MySummary />}></Route>
            <Route path="/project/:id" element={<ProjectPage />}></Route>
            <Route path="/admin-console" element={<AdminConsole />}></Route>
            <Route path='*' element={<NotFound />}></Route>
            <Route path='/blogs' element={<BlogsPage />}></Route>
          </Routes>
          <Footer />

      </main>
            </LoginProvider>
          </MobileMenuProvider>
          {/* </MessageProvider> */}
        </AlertProvider>
      </GlobalDataProvider>
    </ChakraProvider>
  );
}

export default App;
