import { useEffect } from "react";
import FullScreenSection from "../components/FullScreenSection";
import { useGlobalData } from "../context/GlobalDataContext";
import { Box, Heading } from "@chakra-ui/react";

const MySummary = () => {
    const { isDarkBackground } = useGlobalData();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <FullScreenSection 
            py={20}
            justifyContent="top"
            alignItems="center"
            isDarkBackground={isDarkBackground}
            backgroundColor="lightgray"
        >
            <Box width={"80%"}>
            <Heading size={"2xl"} py={10}>Readme</Heading>
            <p style={{fontSize: "18px", marginTop: "15px"}}>
            “Innovation is changing the world”, technology has positive and negative to our life and world, 
             in my past one decade of working experience, I am put my effort to leveraging negative and positive. 
             I’m dreaming of figure out one way to have a greenish technology.    
            </p>
            <p style={{fontSize: "18px", marginTop: "15px"}}>
            As an accomplished Software Developer, I possess a proven track record in System Design, Database Modeling, 
            troubleshooting, and Cloud Architecture. 
            My expertise spans Kubernetes, Stocker, Load Balancing, and encompasses proficiency in software, embedded systems, 
            and cybersecurity development. Proficient in Java, Python, C/C++, networking, SQL, 
            and NO-SQL programming, as well as Machine Learning and database modeling, 
            I bring comprehensive skills to drive technological excellence. 
            Recognized as a proactive problem solver with exceptional communication abilities, 
            I hold multiple Master’s degrees in Computer Science, Electrical Engineering, 
            Machine Learning, and Information Systems. 
            I am enthusiastic about leveraging my technical acumen to contribute to innovative 
            technology initiatives and eager to bring value through my extensive experience and knowledge.
            </p>

            <p style={{fontSize: "18px", marginTop: "15px"}}>
            As a techinical manger, with a decade-long tenure in technical management, I bring a wealth of experience in fostering team growth through effective training methodologies and cultivating a cohesive team ethos. 
            Throughout my professional journey, I spearheaded teams, culminating in the acquisition of over 100 diverse patents and intellectual property rights.
            </p>

            <p style={{fontSize: "18px", marginTop: "15px"}}>
            Throughout my career, I have honed my skills in System Design, Database Modeling, and troubleshooting, allowing me to deliver robust solutions in diverse domains.
            My proficiency spans across Machine Learning techniques encompassing Neural Networks, Recommender Systems, Decision Trees, and Anomaly Detection, providing me with a holistic understanding of data-driven solutions.
            </p>

            <p style={{fontSize: "18px", marginTop: "15px"}}>
            I am adept in Python, Java, C/C++, networking, SQL, NO-SQL programming, and Database Modeling, enabling me to navigate complex technical landscapes with ease. 
            Moreover, my multidisciplinary background in Mathematics, including Algebra, Statistics, and Calculus, further complements my technical repertoire.
            </p>

            <p style={{fontSize: "18px", marginTop: "15px"}}>
            My career journey has encompassed Software Design, Embedded Systems Development, and cybersecurity, allowing me to engage in multifaceted projects and drive impactful results. 
            I take pride in my proactive problem-solving approach, which has been instrumental in delivering innovative solutions while fostering strong communication and collaboration across teams.
            </p>

            <p style={{fontSize: "18px", marginTop: "15px"}}>
            Holding multiple Master’s degrees in Computer Science, Electrical Engineering, and Information Systems, I am committed to continuous learning and innovation. I am enthusiastic about the prospect of leveraging my diverse skill set and experience to drive technological excellence.
            </p>
            </Box>
        </FullScreenSection>
    
    );

};

export default MySummary;