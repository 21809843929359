import AdminLogin from "../components/AdminLogin";
import ContentConsole from "../components/ContentConsole";
import { useLoginContex } from "../context/loginContext";
// import Footer from '../components/Footer';

const AdminConsole = () => {
    const { isLogin } = useLoginContex();
    return (
        <>
         {!isLogin && <AdminLogin />}
         {isLogin && <ContentConsole />}
         {/* <Footer /> */}
        </>
    );
}

export default AdminConsole;