import { Box, HStack } from "@chakra-ui/react";
import { faEnvelope, faBars, faHouse, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub, faMedium } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useEffect, useState } from "react";
import { useMobileMenuContext } from "../context/mobileMenuContext";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalData } from "../context/GlobalDataContext";


// const url = "https://www.h-yao.io:2096/api/geo";

const Header = () => {

    const headerRef = useRef(null);
    const { isOpen, onOpen, onClose } = useMobileMenuContext();
    const { isHomePage, inHomePage, leaveHomePage, serviceUrl } = useGlobalData();
    const [ ipInfo, setIpInfo ] = useState([]);
    const navigate = useNavigate();
    const url = serviceUrl + "/api/geo";

    const handleNavigate = () => {
        navigate(`/blogs`);
        leaveHomePage();
    };

    const socials = [
        {
            id: 1,
            icon: faEnvelope,
            url: "mailto: harvey@h-yao.io",
        },
        {
            id: 2,
            icon: faGithub,
            url: "https://github.com/h-yiu",
        },
        {
            id: 3,
            icon: faLinkedin,
            url: ipInfo.ipInfo === 'HK'? null : "https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=harveyyao",
        },
        {
            id: 4,
            icon: faMedium,
            url: "https://medium.com/@harveyhjyao",
        },
    ];


    const fetchData = () => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {setIpInfo(data)})
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // console.log(ipInfo);
 
    useEffect(() => {
        var prevScrollPos = window.scrollY;

        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const headerElement = headerRef.current;

            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            // console.log(scrollTop, scrollHeight, clientHeight);

            // console.log(currentScrollPos);
            if (!headerElement) {
                return;
            }
            if (currentScrollPos > prevScrollPos && currentScrollPos > 0) {
                // scroll down
                headerElement.style.transform = "translateY(-200px)";
            } else {
                // scroll up
                if ( scrollTop + clientHeight < scrollHeight - 20) {
                    headerElement.style.transform = "translateY(0)";
                } 
            }
            prevScrollPos = currentScrollPos;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    const handleClick = (anchor) => () => {
        const id = `${anchor}-section`;
        const element = document.getElementById(id);
        
        if (element) {
            // const offset = 50;
            const bodyBox = document.body.getBoundingClientRect().top;
            const elementBox = element.getBoundingClientRect().top;
            const elementPosition = elementBox - bodyBox;
            // const position = elementPosition - offset;
            window.scrollTo({
                top: elementPosition,
                behavior: "smooth",
                block: "start",
            });

        }
    };

    // const handleClickBlog = () => {
    //     setOpenMsg(!openMsg);
    // }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        inHomePage();
    };


    return (
        <Box
            position={"fixed"}
            left={0}
            top={0}
            right={0}
            translateY={0}
            backgroundColor={"#18181b"}
            transitionDuration={".3s"}
            transitionProperty={"transform"}
            transitionTimingFunction={"ease-in-out"} 
            height={"68px"}   
            zIndex={1999}  
            ref={headerRef} 
    >
        <Box color={"white"} maxWidth={"1280px"} margin={"0 auto"}>
            <HStack py={5} px={8} justifyContent={"space-between"} alignItems={"center"}
                // css={{
                //     '@media (max-width : 640px)': {
                //         marginTop: "10px",
                //     },
                // }}
            >
                <Link to="/">
                    <button onClick={scrollToTop}>
                        <FontAwesomeIcon icon={faHouse} size="2x" />
                    </button>
                </Link>
                <nav style={{width: "50%", display: "flex", justifyContent: "end"}}>
                    <HStack spacing={8}>

                        {socials.map((social) => (
                            <a className="nav-left" href={social.url} key={social.id} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={social.icon} size="2x" key={social.id} />
                            </a>
                        ))}
                    </HStack>

                </nav>

                {isHomePage &&
                        <HStack>
                            <button onClick={() => {isOpen? onClose() : onOpen()}}>
                                <FontAwesomeIcon className="bars" icon={isOpen? faXmark : faBars} size="2x" />
                            </button>                         
                        </HStack>
                }
                
                <nav className="nav-right">
                {isHomePage && 
                    <HStack spacing={8} style={{display: "fex", justifyContent: 'flex-end'}}>
                   
                        <button onClick={handleNavigate}>My Blog</button>
                        <button onClick={handleClick("projects")}>Projects</button>
                        <button onClick={handleClick("contactme")}>Contact Me</button>
                    </HStack>
                }
                </nav>
            </HStack>
        </Box>

        </Box>
    );

}

export default Header;