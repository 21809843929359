import { useEffect, useState } from "react";
import { VStack, HStack, Button, Input, Heading } from "@chakra-ui/react";
import { useGlobalData } from "../context/GlobalDataContext";
import ContentCard from "../components/ContentCard";
import { useAlertContext } from "../context/alertContext";
import useMyPost from "../hooks/useMyPost";


import FullScreenSection from "../components/FullScreenSection";

const BlogsPage = () => {
    const { leaveHomePage, serviceUrl } = useGlobalData();
    const [ blogs, setBlogs ] = useState([]);
    const [ isMobile, setIsMobile ] = useState(false);
    const [ page, setPage ] = useState(0);
    // const url = "https://www.h-yao.io:2096/api/blogs";
    const url = serviceUrl + "/api/blogs";
    const [ showSubscribe, setShowSubscribe ] = useState(false);
    const [ emailForSubscribe, setEmailForSubscribe ] = useState("");
    const [ isValidEmail, setIsValidEmail ] = useState(false);

    // const postSubscriptionUrl = "http://192.168.4.35:8087/api/new_subscription";
    const postSubscriptionUrl = serviceUrl + "/api/new_subscription";

    const { isLoading, response, submit } = useMyPost();
    const { onOpen } = useAlertContext();

    const handleClickSubscription = (e) => {
        e.preventDefault();
        submit(postSubscriptionUrl, {"email": emailForSubscribe}, "Duplicated");
        setEmailForSubscribe("");
    }

    useEffect(() => {
        if (response !== null) {
            switch (response) {
                case "success":
                    onOpen(response, "I'm delighted to have you on board as a subscriber!")
                    break;
                case "error":
                    onOpen(response, "There is something wrong, please try again later")
                    break;
                default:
                    onOpen("error", "You email was enrolled, please change to another email.")
                    break;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response])


    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop || 0;

        if (windowHeight + scrollTop >= documentHeight) {
            setPage((prev) => prev + 1);
        }
    };

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmailForSubscribe(inputEmail);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailPattern.test(inputEmail));
    }

    useEffect (() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const fetchData = () => {
        fetch(url, {
            // method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'pageNumber': page,
            }
        }).then((response) => response.json())
        .then((data) => {
            // console.log(data);
            setBlogs(data);})
        .catch((error) => console.log(error));
    };

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    useEffect(() => {
        window.scrollTo(0, 0);
        leaveHomePage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FullScreenSection
            py={5}
            justifyContent="top"
            alignItems="center"
            backgroundColor="white"
            spacing={5}
        >
            <VStack spacing={0.5} >
                <HStack 
                    maxWidth={"1280px"} 
                    display={"flex"} 
                    justifyContent={"center"} 
                    align={"start"} 
                    marginTop={isMobile ? "50px":"10px"}
                >
                    {blogs.length > 0 ? 
                    <VStack 
                        spacing={isMobile? 5: 5} 
                        width={isMobile? "96%": "80%"} 
                        padding={isMobile? 4: 20}
                        align={"left"}
                    >
                        <HStack width={"full"} >
                            <Button
                                width={"20%"}
                                color={"white"}
                                py={5}
                                backgroundColor={"#50709F"}
                                onClick={() => setShowSubscribe(!showSubscribe)}
                                fontSize={isMobile? "10px" : "16px"}
                            >SUBSCRIBE</Button>
                            {
                                showSubscribe &&
                                (
                                    <>
                                        <Input
                                            placeholder={"Email"}
                                            value={emailForSubscribe}
                                            onChange={handleEmailChange}
                                        ></Input>
                                        <Button
                                            colorScheme="gray"
                                            isDisabled={!isValidEmail}
                                            isLoading={isLoading}
                                            fontSize={isMobile? "8px" : "14px"}
                                            onClick={handleClickSubscription}
                                        >Submit</Button>
                                    </>
                                )
                            }
                        </HStack>

                        {blogs.map((blog) => (
                            <ContentCard 
                                key={blog.id}
                                title={blog.title}
                                description={blog.description}
                                url={blog.url}
                                img_url={blog.img_url}
                                label={blog.label}
                                min={blog.reading_time}
                                publishedOn={blog.initial_publish}
                                priority={blog.priority}
                                mobileStyle={isMobile}
                            />
                        ))}
                    </VStack>
                    : <Heading as={'h4'} size={"md"} color={"gray"} marginTop={"90px"}>Fetching Data...</Heading>}

                </HStack>
            </VStack>
        </FullScreenSection>
    );

};

export default BlogsPage;