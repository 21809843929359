import React, { useState } from "react";
import "./CustomTextArea.css";
import { HStack } from "@chakra-ui/react";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomTextArea = React.forwardRef( ( {rowHeight, labelText,
        color, fontSize, fontFamily,fontWeight,
        borderLeft, borderRight, borderTop, borderBottom,
        backgroundColor,
        enableLeftBorder,
        editButton=true,
        showNextButton=true,
        firstRef,
        customOnChange,
        ...textAreaProps
        
    }, ref) => {

    const [ text, setText ] = useState('');
    const [ isFocus, setFocus ] = useState(false);
    const [ leftBorder, setLeftBorder ] = useState(false);
    const [ textareaSize, setTextareaSize ] = useState({width: 0, height: 0})

    const handleChange = (e) => {
        const phrases = e.target.value.split('\n');
        setText(phrases[0]);
        customOnChange(phrases[0]);

    };

    const handleKeyUp = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();

            const { selectionStart, selectionEnd, value } = event.target;
            const spaces = '    ';

            const updatedValue = value.substring(0, selectionStart) + spaces + value.substring(selectionEnd);
            event.target.value = updatedValue;
            event.target.setSelectionRange(selectionStart + spaces.length, selectionStart + spaces.length);
            setText(event.target.value);
        }
    }

    const handleFocus = (e) => {
        setFocus(true);
        enableLeftBorder && setLeftBorder(true);

        const { target } = e;
        setTextareaSize({
            width: target.offsetWidth,
            height: target.offsetHeight,
        });
    }

    const handleBlur = () => {
        setFocus(false);
        setLeftBorder(false);
        // setTextareaSize({ width: 0, height: 0});
    }

    const fontWidthNum = parseInt(fontSize.slice(0, fontSize.length - 2));

    const charactersEachRow = Math.floor(textareaSize.width / (fontWidthNum / 2));

    return (
        <HStack 
            tabIndex={0}
            onFocus={handleFocus}
            onBlur={handleBlur}
            width={"100%"} 
            justifyContent={"right"} 
            alignItems={"top"}
        >
        {((editButton && (isFocus || showNextButton)) && text.length === 0 ) && 
            <label 
                    style={{color: "lightgray", 
                            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                            fontSize: "30px",
                            minWidth: "5%",
                            maxWidth: "5%",
                            marinTop: "10px"
                            }}
                > 
                <FontAwesomeIcon icon={faSquarePlus} size="1x" /> 
            </label>}

        <textarea
            {...textAreaProps}
            className="textarea"
            value={text}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            rows={1}
            ref={ref}
            style={{
                // height: `${(text.split(' ').length / 9  + 1) * rowHeight}px`,
                height: `${(text.length / charactersEachRow  + 1.5) * rowHeight}px`,
                color,
                fontSize,
                fontFamily,
                fontWeight,
                borderLeft: leftBorder ? borderLeft: null,
                borderTop,
                borderRight,
                borderBottom,
                backgroundColor,
                minWidth: "90%",
                maxWidth: "90%",
                // borderLeft: leftBorder? '1px solid lightgray' : null,
            }}
        />
        </HStack>
    );
});

export default CustomTextArea;