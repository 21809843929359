import { useState } from "react";

const wait = (m)  => new Promise((resolve) => setTimeout(resolve, m));

const useMyPost = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ response, setReponse ] = useState(null);
    const [ duplicatedNum, setDuplicatedNum ] = useState(0);

    const submit = async (url, data, errorCode) => {
        setIsLoading(true);
        await wait(700);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (!response) {
                throw new Error("Something Wrong");
            }
            return response.json();
        })
        .then((data) => {
            var code = data.result;
            if (code === errorCode) {
                setDuplicatedNum(prevNum => prevNum + 1);
                setReponse("duplicated" + duplicatedNum);
            } else {
                setReponse("success");
            }
            // console.log(data.result);
        })
        .catch((error) => {
            setReponse("error");
            // console.log(error);
        }).finally(() =>
            setIsLoading(false)
        );
    }

    return {isLoading, response, submit};

}

export default useMyPost;