import { useState } from "react";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


/** 
* This is a custom hook that can be used to submit a form and simulate an API call 
* It uses Math.random() to simulate a random success or failure, with 10% chance of error 
*/

const useSubmit = () => {
    const [ isLoading, setLoading ] = useState(false);
    const [ response, setResponse ] = useState(null);

    const submit = async (url, data) => {
        // const random = Math.random();
        // console.log(data);
        setLoading(true);
        var res = {};

        try {
            const enquiryOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, enquiryOptions);

            await wait(1000);

            res = await response.json();
            // console.log(res.result);

            if (!res.result) {
                throw new Error("Something went wrong");
            }

            setResponse({
                type: "success",
                message: `Thanks for your submission ${data.firstName}, I will get back to you shortly!`,
            })
        } catch (error) {
            setResponse({
                type: "error",
                message: "something went wrong, please try again later!",
            })
        } finally {
            setLoading(false);
        }
    };

    return { isLoading, response, submit };
}

export default useSubmit;