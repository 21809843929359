import { Heading, Text, VStack } from "@chakra-ui/react"
import FullScreenSection from "./FullScreenSection"

const NotFound = () => {
    return (
        <FullScreenSection >
            <VStack spacing={10} py={170} px={10}>
                <Heading as={'h1'} size={'4xl'}>&#9842;</Heading>
                <Text color={"GrayText"}>PAGE NOT FOUND</Text>
                <Heading as={'h1'} size={'4xl'} color={'GrayText'}>404</Heading>
                <Text>The requested page cannot be found. Feel free to navigate back to the homepage to explore the content you are looking for.</Text>
            </VStack>
        </FullScreenSection>
    );

};

export default NotFound;