import { VStack, HStack, Image, Text, Heading } from "@chakra-ui/react";


const ContentCard = (props) => {
    const today = new Date();
    const formattedDateUTC = `${today.getUTCMonth() + 1}/${today.getUTCDate()}/${today.getUTCFullYear()}`
    const diffDate = (new Date(formattedDateUTC) - new Date(props.publishedOn)) / (1000 * 60 * 60 * 24)
    var publishStr = "";
    if (props.priority  > 0 ) publishStr = ""; 
    else if (diffDate <= 1)  publishStr = 'today';
    else if (diffDate > 1 && diffDate <= 2) publishStr = 'yesterday';
    else if (diffDate <= 5) publishStr = `${Math.floor(diffDate)} days ago`;
    else publishStr = props.publishedOn;

    // console.log(diffDate) ;

    return (
        <VStack
            color={"#222"}
            cursor={"pointer"}
            align={"left"}
        >
            <span style={{fontSize: "12px"}}>{ publishStr }</span>
            <a href={props.url} target="_blank" rel="noopener noreferrer">
                <HStack spacing={10} >
                    <VStack align={"left"} width={"85%"}>
                        <Heading size={props.mobileStyle?"sm":"md"}>{props.title}</Heading>
                        <Text className="description" fontSize={"md"}>{props.description}</Text>
                    </VStack>
                    <Image src={props.img_url} alt="" height={props.mobileStyle? "60px": "80px"} width={props.mobileStyle?"60px":"80px"}/>
                </HStack>
            </a>
            <HStack py={1}>
                <button 
                style={
                    props.mobileStyle? {
                        background: "#eee",
                        borderRadius: "10px",
                        fontSize: "12px",
                        padding: "5px",
                        width:"auto"
                    }:
                    {background: "#eee", 
                        borderRadius: "10px",
                        fontSize: "14px",
                        padding: "5px",
                        width:"auto"}}
                >
                    {props.label}
                </button>
                <span style={props.mobileStyle? {fontSize:"10px", color:"gray"}:{fontSize: "12px", color: "gray"}}>{props.min} min read</span>
            </HStack>
        </VStack>

    );
};

export default ContentCard;