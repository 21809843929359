import { createRef, useRef, useState } from "react";
import CustomTextArea from "./CustomTextArea";

const DynamicCustomTextArea = ({onMainBody}) => {

    // Manage an array of text areas, initial two areas.
    const [ textAreas, setTextAreas ] = useState(['', '']);

    // Create refs for each text area
    const textRefs = useRef([]);
    const [ showNextButton, setshownextbutton ] = useState(false);
    const [ mainBody, setMainBody ] = useState({});
    const [ text, setText ] = useState('');
    
    const getSelectedText = () => {
        
        if (window.getSelection) {
            return window.getSelection().toString();
        }
        // Internet Explorer
        else if (document.selection && document.selection.type !== 'Control') {
            return document.selection.createRange().text;
        }

        return '';
    }

    const detectSelection = (event, index) => {
        const selection = getSelectedText();
        const ref = textRefs.current[index];

        if (selection.trim() !== '') {
            if (ref) {
                const startPos = ref.selectionStart;
                const endPos = ref.selectionEnd;
          
                const textBefore = ref.value.substring(0, startPos);
                const textAfter = ref.value.substring(endPos);
          
                const insertedText = `<b>${selection}</b>`;
                const newText = textBefore + insertedText + textAfter;
          
                setText(newText);
                ref.value = newText;
                // console.log(newText);
            }
        }
    }


    const getText = (text) => {
        setText(text);
    } 

    const handleKeyDown = (event, index) => {
        const updatedTextAreas = [...textAreas];
        updatedTextAreas[index] = event.target.value;
        setTextAreas(updatedTextAreas);
        if (event.key === 'Enter') {
            // Add a new textarea when Enter is pressed
            setTextAreas([...updatedTextAreas, '']);  
            
            textRefs.current[index] = createRef();
            if (index < textAreas.length - 1) {
                textRefs.current[index + 1].disabled = false;
                textRefs.current[index + 1].focus();
            }
            const addText = {...mainBody};
            var key = index.toString();
            addText[key] = text;
            setMainBody(addText);
            onMainBody(addText);
            // console.log(mainBody);

        } else if (index > 0 && event.target.value === '' 
                  && event.key === 'Backspace' && textAreas.length > 2) {
            updatedTextAreas.splice(index, 1);
            setTextAreas(updatedTextAreas);
            textRefs.current[index - 1].focus();
            var deleteText = {...mainBody};
            var key_temp = index.toString();
            delete deleteText[key_temp];
            setMainBody(deleteText);
            onMainBody(deleteText);
        } else if (event.key === 'Tab') {
            event.preventDefault();
        }
    };

    const handleBlurEntireSection = () => {
        setshownextbutton(true);
        // console.log("test");
    }
    const handleFocusEntireSection = () => {
        setshownextbutton(false);
    }
    return (
        <div style={{width: "100%"}} 
                tabIndex={0} 
                spacing={3}
                onBlur={handleBlurEntireSection} 
                onFocus={handleFocusEntireSection}>
            {textAreas.map((text, index) => (
                    <CustomTextArea 
                        showNextButton={index === textAreas.length - 2  ? showNextButton: false}
                        key={index}
                        rowHeight={30}
                        fontSize={"20px"}
                        value={text}                        
                        autoFocus={index === 0 ? true: false}
                        ref={(ref) => (textRefs.current[index] = ref)}             
                        onKeyDown={(e) => {
                            handleKeyDown(e, index);
                        }}
                        customOnChange={getText}

                        onDoubleClick={(e) => detectSelection(e,index)}
                        onMouseUp={(e) => detectSelection(e, index)}
                    />
            ))}
        </div>
    );
};

export default DynamicCustomTextArea;