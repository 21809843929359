import React from "react";
import FullScreenSection from "./FullScreenSection";
import { useGlobalData } from "../context/GlobalDataContext";
import Card from "./Card";
import { Box, Heading } from "@chakra-ui/react";

const projects = [ 
    { 
      id: 1,
      title: "IoT production/test desktop application", 
      description: 
        "Using the C/C++ programming language to develop a comprehensive sensor-testing desktop application to facilitate the collection of large amounts of sensor data. The parameters are refined based on detailed test data analysis and rewritten to the sensor MCU through the COM connector.", 
      getImageSrc: () => require("../images/photo1.jpg"), 
    }, 
    { 
      id: 2,
      title: "Health parameters mobile app", 
      description: 
        "Utilizing React Native, I developed a cutting-edge mobile application centered around smart home health solutions. This app seamlessly retrieves health data from cloud services via Wi-Fi connectivity, enabling users to send directives to the health cloud for controlling various health-related products.", 
      getImageSrc: () => require("../images/photo2.jpg"), 
    }, 
    { 
      id: 3,
      title: "Large enterprise management cloud", 
      description: 
        "I spearheaded the development of an expansive suite of enterprise management software using Java, tailored specifically for overseeing strategic human resources management within large-scale enterprises. This sophisticated system is built upon a robust Service-Oriented Architecture (SOA), ensuring seamless integration and efficiency across diverse organizational functions.", 
      getImageSrc: () => require("../images/photo3.jpg"), 
    }, 
    { 
      id: 4,
      title: "Invention Patent: Optical intelligent detection system", 
      description: 
        "I invented a detection system based on optical detection and deep machine learning technology. This system can conduct qualitative and quantitative analysis of more than ten substances in liquids, involving health, environment, heavy metals and other indicators. Among them, optics, deep learning and cloud computing technology are applied.", 
      getImageSrc: () => require("../images/photo4.jpg"), 
    }, 
];

const ProjectsSection = () => {

    const { isDarkBackground } = useGlobalData();

    return (
        <FullScreenSection
            isDarkBackground={isDarkBackground}
            backgroundColor={"gray"}
            p={8}
            alignItems={"flex-start"}
            spacing={8}
        >
            <Heading marginTop={25} as="h1" id="projects-section" color={"white"}>
                Featured Projects
            </Heading>
            <Box
                display={"grid"}
                gridGap={8}
                gridTemplateColumns={"repeat(2, minmax(0, 1fr))"}
                css={{
                    '@media (max-width: 640px)': {
                        gridTemplateColumns: '1fr',
                    },
                }}
                marginBottom={20}
            >
                {projects.map((project) => (
                    <Card
                        id={project.id}
                        key={project.title}
                        title={project.title}
                        description={project.description}
                        // url="https://github.com/rgommezz/react-native-offline"
                        ImgSrc={project.getImageSrc()}
                    />
                ))}
            </Box>

        </FullScreenSection>
    );

};

export default ProjectsSection;