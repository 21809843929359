import { VStack, HStack, Image, Text, Heading } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { useGlobalData } from "../context/GlobalDataContext";


const Card = ({id, title, description, ImgSrc }) => {
    const { leaveHomePage } = useGlobalData();
    const navigate = useNavigate();

    const handleNavigate = (id) => {
        navigate(`/project/${id}`);
        leaveHomePage();
    };

    return (
        <VStack
            borderRadius={"xl"}
            color={"black"}
            cursor={"pointer"}
            backgroundColor={"white"}
        >
            <Image borderRadius={"xl"} src={ImgSrc} alt={title} />
            <VStack spacing={4} alignItems={"flex-start"} p={5}>
                <HStack justifyContent={"space-between"} alignItems={"center"}>
                    <Heading as={"h3"} size={"md"}>{title}</Heading>
                </HStack>
                <Text color={"#64748b"} fontSize={"lg"}>{description}</Text>

                <HStack spacing={2} alignItems={"center"} color={"coral"}>
                    {/* <button onClick={() => handleNavigate(id)}> */}
                    <p onClick={() => handleNavigate(id)}>See more</p>
                    <FontAwesomeIcon icon={faArrowRight} size="1x" onClick={() => handleNavigate(id)}/>
                    {/* </button> */}
                </HStack>
            </VStack>
        </VStack>
    );
};

export default Card;