import { useParams } from "react-router-dom";
import FullScreenSection from "../components/FullScreenSection";
import { useGlobalData } from "../context/GlobalDataContext";
import { Box, VStack, Text, Heading } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const ProjectPage = () => {
    const { isDarkBackground } = useGlobalData();
    const { id } = useParams();
    const navigate = useNavigate();

    const handleNavigate = (id) => {
        navigate(`/project/${id}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    return (
        <FullScreenSection
            py={20}
            isDarkBackground={isDarkBackground}
            backgroundColor={"#lightblue"}
        >
            <Box 
                maxWidth={"1280px"}
                py={5}
            >
                { parseInt(id) === 1 &&
                <VStack py={5} spacing={10}>
                    <Heading as="h3" width={"96%"} textAlign={"center"}> IoT production/test desktop application </Heading>
                    <div className="project-layout" style={{backgroundColor: "gray"}}>
                        <img className="graph" src={require("../images/desktop-app.png")} alt=""/>
                        <VStack px={8} color={"white"}>
                            <Text>
                                The objective of this desktop application is to facilitate the post-production adjustment of sensor parameters. 
                                Precision optical sensor products notably rely on environmental and usage factors, necessitating rigorous testing for each batch before shipment. 
                                Prior to testing, the MCU-driven main program employs default parameters. Upon completion of the tests, it becomes imperative to reconfigure the parameters of each batch based on the acquired data. 
                                This entails updates. Consequently, to streamline the production process, our R&D department endeavors to develop a desktop application. 
                                This application aims to seamlessly rewrite the updated parameters into the MCU, circumventing the need for an entire MCU test program update. 
                                This advancement significantly enhances the efficiency of production and testing procedures.
                            </Text>
                            <Text>
                                The primary functions of this Python-based desktop program encompass several key aspects:
                            </Text>
                            <Text>
                                1. Acquiring and managing the computer's serial ports, facilitating concurrent updates across multiple ports. <br/>
                                2. Configuring the communication protocol parameters of the serial ports. <br />
                                3. Establishing communication with the sensor via C language, organizing the protocol. <br />
                                4. Loading the requisite .bin file (compiled in C language) into the program.  <br />
                                5. Writing the .bin file to the connected serial port interfacing with the sensor product.         
                            </Text>
                        </VStack>
                    </div>
                    <div className="project-layout" style={{backgroundColor:"gray"}}>
                        <img className="graph" src={require("../images/sensor_test_app.png")} alt=""/>
                        <VStack px={8} color={"white"}>
                            <Text>
                                "This desktop application serves as sensor testing software designed specifically for factory testing of sensors. 
                                The sensors, once integrated into customers' smart home products, necessitate thorough testing to meet high-volume demands and enhance testing efficiency.
                                All product testing is centralized through this software, enabling simultaneous testing of products connected in batches via serial ports. 
                                Each computer manages numerous serial ports, allowing the software to conduct parallel testing and store test data locally.
                            </Text>
                            <Text>
                                During testing, the software operates by efficiently managing connections to multiple serial ports, 
                                enabling real-time transmission of test data from individual computers to a server. 
                                This data serves as a foundation for machine learning algorithms and facilitates the calculation of necessary parameter adjustments. 
                                These refined parameters are then updated via the software to ensure the products meet stringent accuracy requirements.
                            </Text>
                            <Text>
                                Developed using C++, the software facilitates crucial functionalities such as establishing and managing serial port connections, 
                                configuring diverse parameters like baud rates and data bits, and facilitating seamless switching between initialization and test modes. 
                                Its core function lies in the meticulous execution of comprehensive sensor testing procedures."       
                            </Text>
                        </VStack>
                    </div>
                    <Text textDecoration={"underline"}>
                        <span style={{cursor: "pointer"}} onClick={() => {
                            handleNavigate(2);
                            // leaveHomePage();
                          }}>Next Project</span>
                    </Text>

                </VStack>
                }      

            { parseInt(id) === 2 &&
                <VStack py={5} spacing={10}> 
                    
                    <Heading as="h3" width={"96%"} textAlign={"center"}> Health parameters mobile app </Heading>
                    <div className="project-layout" style={{backgroundColor: "gray"}}>
                        <img className="image-1" src={require("../images/app-1.jpeg")} alt="" />
                        <img className="image-1" src={require("../images/app-2.jpeg")} alt="" />
                    </div>
                    <div className="project-layout" style={{backgroundColor: "gray"}}>
                        <img className="graph" src={require("../images/mobile-app-process.jpeg")} alt=""/>
                        <VStack px={8} color={"white"}>
                            <Text>
                                Routine urine test is one of the "three routines" items of medical examination. 
                                It is a preliminary examination that cannot be ignored in clinical practice and plays an important reference role in the screening and identification of diseases. In recent years, 
                                the incidence of nephropathy in the world has been increasing, with an average of 1 in 10 people with nephropathy.
                            </Text>
                            <Text>
                                This app is working for a brand-new smart urine test toilet, which connected product to cloud.
                                The smart urine test toilet no test strips can collect and analyze daily urine data, 
                                combined with periodic regular statistical analysis and comparison, predict in advance whether the diet structure is reasonable, 
                                daily water intake recommendations, and whether there is inflammation and infection in the urinary system. 
                                The role of the user's physiological cycle early warning and some cancer early warnings.
                            </Text>
                            <Text>
                                Developed with React Native, this application encompasses several key functionalities: <br />
                                1. User registration  <br />
                                2. Connecting and activating products <br />
                                3. Initiating tests   <br />
                                4. Retrieving test results   <br />
                                5. Conducting regular data analysis and uploading data. <br />
                                The left graph is the process of the using product, which are operated on the mobile app.     
                            </Text>
                        </VStack>
                    </div>
                    <Text textDecoration={"underline"}>
                        <span style={{cursor: "pointer", marginRight: "20px"}} onClick={() =>{
                            handleNavigate(1);
                        }}>Previous Project</span> 
                        <span style={{cursor: "pointer"}} onClick={() => {
                            handleNavigate(3);
                          }}>Next Project</span>
                    </Text>
                
                </VStack> }
                { parseInt(id) === 3 && 
                    <VStack py={5} spacing={10}>
                        <Heading as={"h3"} textAlign={"center"}>Large enterprise management cloud</Heading>
                        <div className="project-layout" style={{backgroundColor: "gray"}} >
                            <img className="graph" src={require("../images/hr-app.webp")} alt="" />
                        
                            <VStack px={8} color={"white"}>
                                <Text>
                                    The HRMS cloud model is crafted around inclusive participation within the corporation, 
                                    segregating functionalities into employee self-service and manager self-service. 
                                    Through this framework, employees gain autonomy to review personal information encompassing personal files, wages, leave balance, attendance records, assessments, and more. 
                                    Additionally, they can initiate diverse online personnel approval services and engage in online learning and examinations.
                                </Text>
                                <Text>
                                    Managers, on the other hand, have access to team-related data such as manpower, salary details, and attendance records, enabling them to oversee and process online approvals seamlessly.
                                </Text>
                                <Text>
                                    Drawing from the enterprise shared service operation and management platform, 
                                    the HR cloud system integrates the human resources sharing center within the three-pillar operational model of enterprise human resources management. 
                                    Through this system, employees gain the ability to initiate and accomplish various shared services via mobile phones and personnel shared service machines. 
                                    These services encompass tasks like entry processing, certificate issuance, contract signing, work card production, and accessing HR inquiries.
                                </Text>
                            </VStack>
                        </div>

                        <div className="project-layout" style={{backgroundColor: "chocolate"}} >
                            <img className="graph" src={require("../images/hr-app3.png")} alt="" />
                        
                            <VStack px={8} color={"white"}>
                                <Text>
                                    The HR cloud system is constructed upon the corporate university operational framework. 
                                    Going beyond fulfilling the company's routine training schedules, process implementation, efficacy assessments, credit management, instructor courses, and resource management, it integrates corporate talent career pathways and job qualifications. 
                                    This integration facilitates the creation of a training course system aimed at enhancing employee capabilities and fostering career progression. The system encompasses PC and mobile-based online learning, alongside applications for conducting online examinations and assessments.
                                </Text>
                                <Text>
                                    Derived from the breakdown of corporate strategic objectives, the Kingdee HR cloud system empowers companies to establish a repository of performance indicators. 
                                    It seamlessly executes diverse performance model assessments like target management, KPI evaluation, BSC analysis, OKR tracking, and 360-degree questionnaire assessments. 
                                    These tools aid companies in conducting objective evaluations of employee performance. 
                                    Furthermore, the system amalgamates evaluation outcomes with salary considerations, fostering a robust and constructive corporate performance culture.
                                </Text>
                            </VStack>
                        </div>
                    <Text textDecoration={"underline"}>
                        <span style={{cursor: "pointer", marginRight: "20px"}} onClick={() =>{
                            handleNavigate(2);
                        }}>Previous Project</span> 
                        <span style={{cursor: "pointer"}} onClick={() => {
                            handleNavigate(4);
                          }}>Next Project</span>
                    </Text>

                    </VStack>
                }
                {parseInt(id) === 4 && 
                    <VStack py={5} spacing={10}>
                        <Heading as={"h3"} textAlign={"center"}>Optical intelligent detection system</Heading>
                        <div className="project-layout" style={{backgroundColor: "gray"}}>
                            <img className="graph" src={require("../images/medical-test.jpg")} alt="" />
                            <VStack px={8} color={"white"}>
                                <Text>
                                    <span style={{color:"lightskyblue", fontWeight: "bold"}}>Decreased pH:</span> seen in diabetes, gout, acidosis, chronic glomerulonephritis, etc.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Increased pH:</span> seen in frequent vomiting, urinary tract infections, taking bicarbonate drugs, and alkalosis.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Microalbumin:</span> It is a small amount of albumin that appears in human urine and is usually used as an indicator to monitor the occurrence of kidney disease. Because the detection of microalbumin is the most obvious and reliable indicator for early detection of kidney disease.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Urine:</span> Routine urinalysis can detect early kidney disease, suggesting that there may be some underlying diseases in the body, and it is necessary for patients to further clarify the diagnosis of kidney disease.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Blood:</span> It can check for high blood pressure, high blood sugar, anemia, and whether the body has been infected with certain diseases.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Stool:</span> Mainly to determine whether the digestive system is infected with certain diseases
                                </Text>
                                <Text>
                                    The whole process is completed by the intelligent cloud detection system using a non-contact, real-time online urine test, which will not increase the burden on customers.
                                </Text>
                            </VStack>
                        </div>
                        <div className="project-layout" style={{backgroundColor: "gray"}}>
                            <img className="graph" src={require("../images/deep-learning.jpg")} alt="" />
                            <VStack px={8} color={"white"}>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Data Collection:</span> The primary data source is derived from test data. Throughout the testing procedure, each product undergoes five tests, producing 10 data sets per test. These data sets are automatically stored in separate CSV files. Every minute, a CSV file is generated and swiftly transmitted to the server for secure storage.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Data Clean:</span> The core of data processing relies on Python programs designed to automate the removal of duplicate entries and identify any anomalous or unreasonable signals within the dataset for subsequent deletion. Following this cleansing, the data is structured into a key-value data grouping format.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Data transfer: </span> The transfer of data occurs via the process of publishing and subscribing to Kafka topics.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Model reinforcement learning:</span> Utilizing TensorFlow, a pre-defined model is scripted. Daily, upon receiving batch data, the model's layer outcomes undergo reinforcement training.
                                </Text>
                                <Text>
                                    <span style={{color: "lightskyblue", fontWeight: "bold"}}>Model Application:</span> The model application occurs on a weekly basis. This week's model parameters are remotely uploaded to the customer's online detection sensors to enhance detection accuracy.
                                </Text>

                            </VStack>
                        </div>
                    <Text textDecoration={"underline"}>
                        <span style={{cursor: "pointer", marginRight: "20px"}} onClick={() =>{
                            handleNavigate(3);
                        }}>Previous Project</span> 
                    </Text>

                    </VStack>
                }
            </Box>
        </FullScreenSection>
    );

};

export default ProjectPage;