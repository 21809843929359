import { Button, VStack, Box, HStack, Avatar, Heading, Text } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import { useState, useEffect } from "react";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentCard from "./ContentCard";
import { useGlobalData } from "../context/GlobalDataContext";
import { Link } from "react-router-dom";
import WritePost from "./WritePost";



// const url = "https://www.h-yao.io:8443/blogs";


const ContentConsole = () => {

    const [ editMode, setEditMode ] = useState(false);
    const [ blogs, setBlogs ] = useState([]);

    const [ isMobile, setIsMobile ] = useState(false);
    const { myName, greeing, bio1, bio2, bio3, leaveHomePage, baseUrl } = useGlobalData();
    const url = baseUrl + "/blogs";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const fetchData = () => {
        fetch(url)
         .then((response) => response.json())
         .then((data) => setBlogs(data))
         .catch((error) => console.log(error));
    }
    
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 780);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    const handleEditMode = () => {
        setEditMode(!editMode);
    }

    return (
        <FullScreenSection
            py={20}
            justifyContent="center"
            alignItems="center"
            backgroundColor="white"
            spacing={8}
        >
            <VStack spacing={0.5} width={"100%"}>
                {isMobile && 
                        <HStack width={"90%"} marginTop={"50px"} spacing={3}> 
                            <Link to="/summary">
                                <Avatar
                                    onClick={leaveHomePage}
                                    src={require("../images/myavatar.jpeg")}
                                    size={"lg"}
                                    name={myName}
                                />
                            </Link>
                            <VStack spacing={0.1} align={"left"}>
                                <Text fontSize={"sm"}>Hello, I am Havey</Text>
                                <Text  fontSize={"sm"} noOfLines={1} color={"gray"}>A full-stack developer, interdiciplinary</Text>
                                <Text  fontSize={"sm"} noOfLines={1} color={"gray"}>researcher, technical manager</Text>
                            </VStack>
                        </HStack>

                }
                
                { !editMode &&
                <Box 
                    width={"100%"}
                    height="50px" 
                    display={"flex"}
                    justifyContent={"left"}
                    alignItems={"center"}
                    px={10}
                    // backgroundColor={"#eeeeee"}
                    borderRadius={"5px"}
                >
                    <Button 
                        height={"30px"}
                        borderRadius={5}
                        padding={2}
                        backgroundColor={'white'} 
                        fontSize={"14px"}   
                        onClick={handleEditMode} 
                    >
                        <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                        <span style={{paddingLeft: "10px"}}>write</span>
                    </Button> 
                    
                </Box> }

                {!editMode ? 
                <HStack alignItems={"top"} width={"100%"}>

                        <VStack 
                            spacing={isMobile? 1: 3} 
                            width={isMobile? "96%": "70%"} 
                            paddingLeft={isMobile? 4: 20}
                            paddingRight={isMobile? 4: 20}
                            py={10}
                            align={"center"}
                        >
                            {blogs.map((blog) => (
                                <ContentCard 
                                    key={blog.id}
                                    title={blog.title}
                                    description={blog.description}
                                    url={blog.url}
                                    img_url={blog.img_url}
                                    label={blog.label}
                                    min={blog.reading_time}
                                    publishedOn={blog.initial_publish}
                                    priority={blog.priority}
                                    mobileStyle={isMobile}
                                />
                            ))}
                        </VStack> :

                    {isMobile ? null :
                    <VStack 
                        className="my-bio" 
                        spacing={3} width={"25%"} 
                        alignItems={"left"} 
                        marginLeft={"30px"} 
                        py={10}
                    >
                        <VStack spacing={4} alignItems={"left"} height={"160px"} >
                            <Avatar
                                src={require("../images/myavatar.jpeg")}
                                size={"xl"}
                                name={myName}
                            />
                            <Heading as="h5" size={"sm"} noOfLines={1}>{greeing}</Heading>
                        </VStack>
                        <VStack spacing={1} alignItems={"left"}>
                            <Text  fontSize={"md"} noOfLines={1} color={"gray"}>{bio1}</Text>
                            <Text  fontSize={"md"} noOfLines={1} color={"gray"}>{bio2}</Text>
                            <Text noOfLines={1} color={"gray"}>{bio3}</Text>
                            {/* <Heading as={"h3"} size={"lg"} noOfLines={1}>{bio4}</Heading> */}
                            <br />
                            <Link to="/summary" style={{color: "gray", fontSize: "12px"}}>
                                <button onClick={leaveHomePage} style={{textDecoration: "underline"}}>Details...</button>
                            </Link>
                            <br />
                            <br />
                            <Text fontSize={"md"} color={"darkgray"}>📚Monthly Book Recommendation</Text>
                            <Text fontSize={"sm"} color={"#abcccc"}>
                                <span style={{fontWeight: "bolder", textDecoration: "underline", fontStyle:"italic"}}>Good to Great</span> by Jim Collins. 
                                This book analyzes why some companies excel while others don't and identifies key strategies and principles for sustained business success.</Text>
                        </VStack>

                    </VStack> 
                    }

                </HStack> : 
                <WritePost 
                editMode={editMode}
                onCloseEdit={handleEditMode}/>
        }
                
            </VStack>

        </FullScreenSection>
    );

};

export default ContentConsole;