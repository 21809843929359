import { createContext, useContext, useState } from "react";

const LoginContext = createContext(undefined);

export const LoginProvider = ({children}) => {
    const [ state, setState ] = useState({
        isLogin: false,
        userName: '',
        token: '',
    });

    return (
        <LoginContext.Provider
            value={{
                ...state,
                login: (user) => setState({
                    isLogin: true,
                    userName: user,
                }),
                logout: () => setState({
                    isLogin: false,
                    userName: '',
                    token: '',
                }),
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};

export const useLoginContex = () => useContext(LoginContext);

