import { useState, createContext, useContext } from "react";


const MobileMenuContext = createContext(undefined);

export const MobileMenuProvider = ({ children }) => {
    const [ state, setState ] = useState({
        isOpen: false,
    })

    return (
        <MobileMenuContext.Provider
            value={{
                ...state,
                onOpen: () => {
                    setState({isOpen: true});
                    // Disable scrolling on the body
                    document.body.style.overflow = 'hidden';    
                },
                onClose: () => {
                    setState({isOpen: false});
                    // Restoring scrolling on the body
                    document.body.style.overflow = '';
                },
            }}
        >
            {children}
        </MobileMenuContext.Provider>
        
    );
}

export const useMobileMenuContext = () => useContext(MobileMenuContext);