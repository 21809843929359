import { VStack, HStack } from "@chakra-ui/react";
import { useMobileMenuContext } from "../context/mobileMenuContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub, faMedium } from "@fortawesome/free-brands-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useGlobalData } from "../context/GlobalDataContext";
import { useNavigate } from "react-router-dom";


var socials = [];

const MobileMenu = ({children}) => {

    const [ ipInfo, setIpInfo ] = useState([]);

    const { isOpen } = useMobileMenuContext();
    const { serviceUrl } = useGlobalData();
    const url = serviceUrl + "/api/geo";


    socials = [
        {
            id: 1,
            icon: faEnvelope,
            url: "mailto: harveyhjyao@gmail.com",
            text: "Email to me",
        },
        {
            id: 2,
            icon: faGithub,
            url: "https://github.com/hyao35",
            text: "My Github",
        },
        {
            id: 3,
            icon: faLinkedin,
            url: ipInfo.ipInfo === 'HK'? null : "https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=harveyyao",
            text: "My LinkedIn",
        },
        {
            id: 4,
            icon: faMedium,
            url: "https://medium.com/@sureskills",
            text: "My Medium",
        },
    ];

    const fetchData = () => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {setIpInfo(data)})
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        isOpen && (
            <>
                <div className="Overlay" >
                    <div className="Menu" >
                        {children}
                    </div>
                </div>
            </>
        )
    );

};

const Menu = () => {

    const { onClose } = useMobileMenuContext();
    const componentRef = useRef(null);
    const { leaveHomePage } = useGlobalData();
    const navigate = useNavigate();

    const handleNavigate = () => {
        leaveHomePage();
        navigate(`/blogs`);
        onClose();
    }

    useEffect(() => {
        const handleMousePositionChange = (e) => {

            if ( componentRef.current && e.clientY > 68 &&
                componentRef.current.getBoundingClientRect().width > 0 && componentRef.current.getBoundingClientRect().height > 0 &&
                 (e.clientX < window.innerWidth - componentRef.current.getBoundingClientRect().width ||
                  e.clientY > componentRef.current.getBoundingClientRect().height + 68)) {
                onClose();
            }
        };

        window.addEventListener("mouseup", handleMousePositionChange);

        return () => {
            window.removeEventListener("mouseup", handleMousePositionChange);
        };
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);

    const handleClick = (anchor) => () => {
        const id = `${anchor}-section`;
        const element = document.getElementById(id);
        
        if (element) {
            // const offset = 50;
            const bodyBox = document.body.getBoundingClientRect().top;
            const elementBox = element.getBoundingClientRect().top;
            const elementPosition = elementBox - bodyBox;
            // const position = elementPosition - offset;
            window.scrollTo({
                top: elementPosition,
                behavior: "smooth",
                block: "start",
            });
            onClose();
        }
    };

    return (
        <MobileMenu>
            <VStack spacing={6}
                    p={10}
                    height={"100%"}
                    justifyContent={"space-between"}
                    ref={componentRef}
                    >
                    
                    <HStack alignItems={"left"} spacing={6} width={"100%"} >
                    {socials.map((social) =>  (
                            <a href={social.url} key={social.id} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={social.icon} size="2x" key={social.id} /> 
                            </a>
                        )
                    )}
                    </HStack>
                    <VStack
                        // marginTop={20}
                        width={"100%"}
                        alignItems={"left"}
                    >

                        <button style={{textAlign:"left", width: "50%", fontSize: "22px", fontWeight: "bold"}} 
                                onClick={handleNavigate}>My Blog</button>
                        <button style={{textAlign:"left", width: "50%", fontSize: "22px", fontWeight: "bold"}} 
                                onClick={handleClick("projects")}>Projects</button>
                        <button style={{textAlign:"left", width: "50%", fontSize: "22px", fontWeight: "bold"}} 
                                onClick={handleClick("contactme")}>Contact Me</button>
                    </VStack>

            </VStack>
        </MobileMenu>
    );
}

// export const MenuWithMousePosition = withMousePosition(Menu);

export default Menu;