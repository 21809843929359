import { VStack, HStack, Box, Button, Input } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import CustomTextArea from "../inputComponents/CustomTextArea";
import DynamicCustomTextArea from "../inputComponents/DynamicCustomTextArea";
import { useGlobalData } from "../context/GlobalDataContext";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// const postUrl = "http://192.168.4.35:8087/api/new_blog_meta";
// const postUrl2 = "http://192.168.4.35:8087/api/new_blog";

// const postUrl = "https://www.h-yao.io:2096/api/new_blog_meta";

const WritePost = ({editMode, onCloseEdit}) => {

    const [ usrname, setUsrName ] = useState('');
    const [ pw, setPw ] = useState('');

    const [ mainBody, setMainBody ] = useState({});

    const [ isLoading, setLoading ] = useState(false);

    const [ windowSize, setWindowSize ] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [ isMobile, setIsMobile ] = useState(false);
    const { serviceUrl } = useGlobalData();
    const postUrl = serviceUrl + "/api/new_blog_meta";
    const postUrl2 = serviceUrl + "/api/new_blog";

    const titleRef = useRef();
    const desRef = useRef();
    const urlRef = useRef();
    const imgUrlRef = useRef();
    const keyLabelRef = useRef();
    const readingTimeRef = useRef();

    const [ title, setTitle ] = useState("");
    const [ desp, setDesp ] = useState("");
    const [ url, setUrl ] = useState("");
    const [ imgUrl, setImgUrl ] = useState("");
    const [ keyLabel, setKeyLabel ] = useState("");
    const [ readingTime, setReadingTime ] = useState("");

    const handleUserNameChange = (e) => {
        e.preventDefault();
        setUsrName(e.target.value);
    }

    const handlePwChange = (e) => {
        e.preventDefault();
        setPw(e.target.value);
    }

    const fetchData = () => {
        const today = new Date();
        const basicAuth = 'Basic ' + btoa(usrname + ":" + pw);

        fetch(postUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Auth': basicAuth,
            },
            body: JSON.stringify({
                "title": title,
                "description": desp,
                "priority": 0,
                "url": url,
                "img_url": imgUrl,
                "label": keyLabel,
                "publishedAt": today,
                "reading_time": readingTime,
            })
        }).then(
            (response) => response.json()
            .then(
                (data) => {
                    var blogId = data.result;
                    mainBody["blogId"] = blogId;
                    fetch(postUrl2, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Auth': basicAuth,
                        },
                        body: JSON.stringify(mainBody),
                    }).then ((res) => res.json())
                    .then((data) => console.log(data))
                    .catch((error) => console.log(error))
                    // console.log(mainBody);
                }
            )
            .catch((error) => {console.log(error)})
        )
    }

    const handleKeyDownTitle = (e) => {
        if (e.key === 'Enter') {
            desRef.current.focus();
        } else if (e.key === 'Tab') {
            e.preventDefault();
        }
    }

    const handleKeyDownDesp = (e) => {
        if (e.key === 'Enter') {
            urlRef.current.focus();
        } else if (e.key === 'Backspace' && !e.target.value) {
            titleRef.current.focus();
        } else if (e.key === 'Tab') {
            e.preventDefault();
        }
    }
    
    const handleKeyDownUrl = (e) => {
        if (e.key === 'Enter') {
            imgUrlRef.current.focus();
        } else if (e.key === 'Backspace' && !e.target.value) {
            desRef.current.focus();
        } else if (e.key === 'Tab') {
            e.preventDefault();
        }
    }

    const handleKeyDownImgUrl = (e) => {
        if (e.key === 'Backspace' && !e.target.value) {
            urlRef.current.focus();
        } else if (e.key === 'Enter') {
            keyLabelRef.current.focus();
        } else if (e.key === 'Tab') {
            e.preventDefault();
        } 
    }

    const handleKeyDownKeyLabel = (e) => {
        if (e.key === 'Backspace' && !e.target.value) {
            imgUrlRef.current.focus();
        } else if (e.key === 'Enter') {
            readingTimeRef.current.focus();
        } else if (e.key === 'Tab') {
            e.preventDefault();
        }
    }

    const handleKeyReadingTime = (e) => {
        if (e.key === 'Backspace' && !e.target.value) {
            keyLabelRef.current.focus();
        } else if (e.key === 'Tab') {
            e.preventDefault();
        }
    }

    const handleTitleChange = (text) => {
        setTitle(text);
    };

    const handleDespChange = (text) => {
        setDesp(text);
    }

    const handleUrlChange = (url) => {
        setUrl(url);
    }

    const handleImgUrl = (text) => {
        setImgUrl(text);
    }

    const handleKeyLabel = (text) => {
        setKeyLabel(text);
    }

    const handleReadingTime = (text) => {
        setReadingTime(text)
    }

    const handlePublish = async () => {

        try {
            setLoading(true);
            await wait(700);
            fetchData();
        } catch (err) {
            alert(err);
        } finally {
            setLoading(false);
        }

        
        onCloseEdit();
    }
    // console.log(title + " " + desp + " " + url + " " + imgUrl);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                ...windowSize,
                width: window.innerWidth,
                height: window.innerHeight,
            });
            setIsMobile(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize]);


    const getMainBody = (body) => {
        setMainBody(body);
    }

    // console.log(mainBody);

    return (
        <VStack 
                width={windowSize.width * 0.85}
                minHeight={windowSize.height * 0.9}
                display={"flex"}
                justifyContent={"top"}
                alignItems={"end"}
                spacing={0.1}
                // paddingLeft={isMobile? 4: 20}
                paddingRight={isMobile? 4: 20}
                // backgroundColor={"coral"}
        >
             { editMode &&
                <Box 
                    width={"100%"}
                    height="50px" 
                    display={"flex"}
                    justifyContent={"left"}
                    alignItems={"center"}
                    px={10}
                    // backgroundColor={"#eeeeee"}
                    borderRadius={"5px"}
                >

                    <Button 
                        height={"30px"}
                        borderRadius={"15px"}
                        padding={3}
                        backgroundColor={'green'} fontSize={"12px"} color={"white"} 
                        // onClick={onCloseEdit}
                        onClick={handlePublish}
                        isDisabled={!title || !desp || !url || !imgUrl || !keyLabel}
                        isLoading={isLoading}
                        type="submit"
                        colorScheme="gray"
                    > Publish </Button>
                    
                </Box> }
            <HStack 
                py={5}
                width={"100%"}
                display={"flex"}
                justifyContent={"end"} 
                alignItems={"center"}
                // backgroundColor={"red"}
            >

                <VStack width={"96%"} spacing={0.5}>
                    <Input width={"30%"} color={"gray"} placeholder={"username"} value={usrname} onChange={handleUserNameChange}/>
                    <Input width={"30%"} color={"gray"} placeholder={"password"} value={pw} onChange={handlePwChange} />
                    <CustomTextArea
                        ref={titleRef}
                        rowHeight={50}
                        placeholder='title'
                        fontSize="30px"
                        fontFamily={"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}
                        fontWeight={"bold"}
                        enableLeftBorder={true}
                        borderLeft={ '1px solid lightgray'}
                        editButton={false}
                        text_1={title}
                        // value={title}
                        onKeyDown={handleKeyDownTitle}
                        customOnChange={handleTitleChange}
                    />
                    <CustomTextArea
                        ref={desRef}
                        rowHeight={20}
                        placeholder='description'
                        fontSize="18px"
                        fontFamily={"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}
                        enableLeftBorder={true}
                        borderLeft={ '1px solid lightgray'}
                        editButton={false}
                        onKeyDown={handleKeyDownDesp}
                        customOnChange={handleDespChange}
                    />
                    <CustomTextArea
                        ref={urlRef}
                        rowHeight={20}
                        placeholder='URL'
                        fontSize="18px"
                        fontFamily={"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}
                        enableLeftBorder={true}
                        borderLeft={ '1px solid lightgray'}
                        editButton={false}
                        onKeyDown={handleKeyDownUrl}
                        customOnChange={handleUrlChange}
                    />
                    <CustomTextArea
                        ref={imgUrlRef}
                        rowHeight={20}
                        placeholder='Your title image url'
                        fontSize="18px"
                        fontFamily={"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}
                        enableLeftBorder={true}
                        borderLeft={ '1px solid lightgray'}
                        editButton={false}
                        onKeyDown={handleKeyDownImgUrl}
                        customOnChange={handleImgUrl}
                    />
                    <CustomTextArea
                        ref={keyLabelRef}
                        rowHeight={20}
                        placeholder='Key Label'
                        fontSize="18px"
                        fontFamily={"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}
                        enableLeftBorder={true}
                        borderLeft={ '1px solid lightgray'}
                        editButton={false}
                        onKeyDown={handleKeyDownKeyLabel}
                        customOnChange={handleKeyLabel}
                    />
                    <CustomTextArea
                        ref={readingTimeRef}
                        rowHeight={20}
                        placeholder='Reading time'
                        fontSize="18px"
                        fontFamily={"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}
                        enableLeftBorder={true}
                        borderLeft={ '1px solid lightgray'}
                        editButton={false}
                        onKeyDown={handleKeyReadingTime}
                        customOnChange={handleReadingTime}
                    />
                </VStack>
            </HStack>
            <HStack 
                py={2}
                width={"100%"}
                display={"flex"}
                justifyContent={"end"} 
                alignItems={"center"}
                tabIndex={10}
            >
                <VStack width={"96%"} >
                 <DynamicCustomTextArea 
                    onMainBody={getMainBody}
                 />
                </VStack>
            </HStack>
            

        </VStack>
    );
};

export default WritePost;