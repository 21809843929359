import { Box, Flex } from "@chakra-ui/react";

import { useGlobalData } from "../context/GlobalDataContext";

const Footer = () => {
    const { myName } = useGlobalData();
    return (
        <Box backgroundColor={"#18181b"}>
            <footer>
                <Flex
                    margin={"0 auto"}
                    px={12}
                    color={"white"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    maxWidth={"1024px"}
                    height={"40px"}
                >
                    <p>{myName} • © 2023</p>
                </Flex>
            </footer>
        </Box>
    );
};

export default Footer;