import { createContext, useContext, useState } from "react";


const GlobalDataContext = createContext(undefined);

const isDarkBackground = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
const userAgent = navigator.userAgent;

export const GlobalDataProvider = ({ children }) => {
    const [ isHomePage, setHomePage ] = useState(true);

    return (
        <GlobalDataContext.Provider
            value={{
                isHomePage,
                myName: "Harvey Yao",
                greeing: "Hello, I am Harvey",
                bio1: "A full-stack developer",
                bio2: "interdisciplinary researcher",
                bio3: "technical manager",
                bio4: "mastered Machine Learning",
                isDarkBackground: isDarkBackground,
                userAgent: userAgent,
                // baseUrl: "https://www.h-yao.io:8443",
                // baseUrl: "http://localhost:8087/api",
                // serviceUrl: "https://www.h-yao.io:2096",
                // serviceUrl: "https://www.heasor.com:2096",
                baseUrl: "https://hyao.heasor.com:8445",
                serviceUrl: "https://hyao.heasor.com:8443",
                inHomePage: () => setHomePage(true),
                leaveHomePage: () => setHomePage(false),
            }}
        >
            {children}
        </GlobalDataContext.Provider>
    );
};

export const useGlobalData = () => useContext(GlobalDataContext);

