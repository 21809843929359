import React from "react";
import { VStack } from "@chakra-ui/react";

const FullScreenSection = ({children, isDarkBackground, ...boxProps}) => {
    return (
        <VStack
            backgroundColor={boxProps.backgroundColor}
            color={boxProps.color}
            // color={isDarkBackground ? "white" : "black"}
        >
            <VStack maxWidth="1280px" minHeight="100vh" {...boxProps} >
                {children}
            </VStack>

        </VStack>
    );
};

export default FullScreenSection;
