import { useState } from "react";
import { useLoginContex } from "../context/loginContext";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const useAuthSubmit = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ response, setResponse ] = useState(null);

    const { login } = useLoginContex();

    const authSumbit = async (url, data) => {
        setIsLoading(true);
        var res = {};

        const basicAuth = 'Basic ' + btoa(data.userName + ":" + data.password);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': basicAuth
                    'Auth': basicAuth
                },
                body: JSON.stringify(data)
            });

            // console.log(headersObj);

            await wait(700);

            if(!response.ok) {
                throw new Error("An error occurred: " +  response.status);
            }


            res = await response.json();


            if (res.cstatus === "002") {
                throw new Error("User name or password are incorrect.");
            }

            if (res.cstatus === '003') {
                login(data.userName);
            }

        } catch(error) {
            setResponse({
                type: "error",
                message: error.toString(),
            })
            
        } finally {
            setIsLoading(false);
        }
    };

    return { isLoading, response, authSumbit };
}

export default useAuthSubmit;