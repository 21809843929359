import LandingSection from '../components/LandingSection';
import ProjectsSection from '../components/ProjectsSection';
import ContactMeSection from '../components/ContactMeSection';
// import Footer from '../components/Footer';
import Menu from '../components/MobileMenu';
import { useEffect } from 'react';

export const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Menu />
            <LandingSection />
            <ProjectsSection />
            <ContactMeSection />
        </>
    );
}