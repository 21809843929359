import React, { useEffect, useState } from "react";
import { Avatar, VStack, Heading, HStack, Text } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import { useGlobalData } from "../context/GlobalDataContext";
import { Link } from 'react-router-dom';
import ContentCard from "./ContentCard";

// const url = "https://www.h-yao.io:8443/blogs";

const LandingSection = () => {
    const { myName, greeing, bio1, bio2, bio3, isDarkBackground, leaveHomePage, serviceUrl } = useGlobalData();
    const [ blogs, setBlogs ] = useState([]);
    const [ isMobile, setIsMobile ] = useState(false);
    // const url = baseUrl + "/blogs";
    const url = serviceUrl + "/api/blogs"


    const fetchData = () => {
        fetch(url, {
            // method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'pageNumber': 1,
            }
        }).then((response) => response.json())
        .then((data) => {
            // console.log(data);
            setBlogs(data);})
        .catch((error) => console.log(error));
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 780);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    // console.log(blogs);


    return (
        <FullScreenSection 
            py={10}
            justifyContent="top"
            alignItems="center"
            isDarkBackground={isDarkBackground}
            // backgroundColor="#4F4F65"
            backgroundColor="white"
            spacing={8}
            paddingLeft={"7px"}
        >
            <VStack spacing={0.5}>
                {isMobile && 
                        <HStack width={"90%"} marginTop={"50px"} spacing={3}> 
                            <Link to="/summary">
                                <Avatar
                                    onClick={leaveHomePage}
                                    src={require("../images/myavatar.jpeg")}
                                    size={"lg"}
                                    name={myName}
                                />
                            </Link>
                            <VStack spacing={0.1} align={"left"}>
                                <Text fontSize={"sm"}>Hello, I am Havey</Text>
                                <Text  fontSize={"sm"} noOfLines={1} color={"gray"}>A full-stack developer, interdiciplinary</Text>
                                <Text  fontSize={"sm"} noOfLines={1} color={"gray"}>researcher, technical manager</Text>
                            </VStack>
                        </HStack>

                }
                <HStack 
                    maxWidth={"1280px"} 
                    display={"flex"} 
                    justifyContent={"space-between"} 
                    align={"start"} 
                    marginTop={isMobile?null:"15px"}
                >
                    <VStack 
                        spacing={isMobile? 1: 3} 
                        width={isMobile? "96%": "70%"} 
                        padding={isMobile? 4: 20}
                        align={"left"}
                    >
                        {blogs ? blogs.map((blog) => (
                            <ContentCard 
                                key={blog.id}
                                title={blog.title}
                                description={blog.description}
                                url={blog.url}
                                img_url={blog.img_url}
                                label={blog.label}
                                min={blog.reading_time}
                                publishedOn={blog.initial_publish}
                                priority={blog.priority}
                                mobileStyle={isMobile}
                            />
                        )) : <span>Fetching Data...</span>}
                        <br />
                        {/* <a href="https://h-yao.site" alt="my-blog" target="_blank" rel="noopener noreferrer" 
                        style={{color: "gray", fontSize: "12px", textDecoration: "underline"}}>See More...</a> */}
                        <Link to={"/blogs"}
                                style={{color: "gray", fontSize: "12px", textDecoration: "underline"}}
                        >
                            <button onClick={leaveHomePage} style={{textDecoration: "underline"}}>
                                See more...
                            </button>
                        </Link>
                    </VStack>

                    {isMobile ? null :
                    <VStack 
                        className="my-bio" 
                        spacing={3} width={"25%"} 
                        alignItems={"left"} 
                        marginLeft={"30px"} 
                        py={2}
                    >
                        {/* <Heading color={"#F7311B"}>Happy New Year!</Heading> */}
                        <VStack spacing={4} alignItems={"left"} height={"160px"} marginTop={"70px"} >
                            <Avatar
                                src={require("../images/myavatar.jpeg")}
                                size={"xl"}
                                name={myName}
                            />
                            <Heading as="h5" size={"sm"} noOfLines={1}>{greeing}</Heading>
                        </VStack>
                        <VStack spacing={1} alignItems={"left"}>
                            <Text  fontSize={"md"} noOfLines={1} color={"gray"}>{bio1}</Text>
                            <Text  fontSize={"md"} noOfLines={1} color={"gray"}>{bio2}</Text>
                            <Text noOfLines={1} color={"gray"}>{bio3}</Text>
                            {/* <Heading as={"h3"} size={"lg"} noOfLines={1}>{bio4}</Heading> */}
                            <br />
                            <Link to="/summary" style={{color: "gray", fontSize: "12px"}}>
                                <button onClick={leaveHomePage} style={{textDecoration: "underline"}}>Details...</button>
                            </Link>
                            <br />
                            <br />
                            <Text fontSize={"md"} color={"darkgray"}>📚Monthly Book Recommendation</Text>
                            <Text fontSize={"sm"} color={"#abcccc"}>
                                <span style={{fontWeight: "bolder", textDecoration: "underline", fontStyle:"italic"}}>Good to Great</span> by Jim Collins. 
                                This book analyzes why some companies excel while others don't and identifies key strategies and principles for sustained business success.</Text>
                        </VStack>

                    </VStack> 
                    }

                </HStack>
            </VStack>

    </FullScreenSection>
    );
};

export default LandingSection;