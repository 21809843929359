import { Box, Button, FormControl, FormErrorMessage, FormLabel,
         Heading, Input, Select, Textarea, VStack} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullScreenSection from "./FullScreenSection";
import { useGlobalData } from "../context/GlobalDataContext";
import { useEffect, useState } from "react";
import useSubmit from "../hooks/useSubmit";
import { useAlertContext } from "../context/alertContext";

// const url = "http://localhost:8080/enquiry";
// const url = "//184.72.132.90:8080/enquiry";
// const url = "https://184.72.132.90:8443/enquiry";
const url = "https://www.h-yao.io:8443/enquiry";

const ContactMeSection = () => {
    const { isDarkBackground } = useGlobalData();
    var width = window.innerWidth;
    const [ windowWidth, setWindowWidth ] = useState(width);
    // Need to handle the color and background color for browers on Windows
    // Since the background of Select options are white, so white text is invisible in Windows.
    // const isWindowsOS = userAgent.includes("Windows");
    const { isLoading, response, submit } = useSubmit();
    const { onOpen } = useAlertContext();

    const formik = useFormik({
        initialValues: {
            firstName: "",
            email: "",
            type: "hireMe",
            comment: "",
        },
        
        onSubmit: (values, ) => {
            submit(url, values);

        },

        validationSchema: Yup.object({
            firstName: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email address").required("Required"),
            // comment: Yup.string()
            //     .min(25, "Must be at least 25 characeters")
            //     .required("Required"),
            comment: Yup.string().trim()
                    .test('more than 10 words',
                          'Commenet must be more than 10 words', (value) => {
                            if (!value) return false;
                            const words = value.trim().split(/\s+/);
                            return words.length > 10;
                          })
                    .required("Required"),
        }),
    });


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener('resize', handleResize);
        return ( () => {
            window.removeEventListener('resize', handleResize);
        });
    }, []);

    useEffect(() => {
        // console.log(response);
        if (response != null) {
            onOpen(response.type, response.message);
            if (response.type === 'success') {
                formik.resetForm();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    

    return (
        <FullScreenSection
            isDarkBackground={isDarkBackground}
            backgroundColor={"#808F70"}
            py={10}
            spacing={8}
            color="white"
        >
            <VStack marginTop={"10px"} width={windowWidth} p={3} alignItems={"center"} >
                <Heading as="h1" id="contactme-section">Contact me</Heading>
                <Box p={4} rounded={"md"} width={"90%"}>
                    <form onSubmit={(e) => {
                        formik.handleSubmit();
                        e.preventDefault();
                    }}>
                        <VStack spacing={8}>
                            <FormControl isInvalid={ formik.touched.firstName && formik.errors.firstName ? true : false }>
                                <FormLabel htmlFor="firstName">Name</FormLabel>
                                <Input 
                                    id="firstName"
                                    name="firstName"
                                    {...formik.getFieldProps("firstName")}
                                />
                                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={ formik.touched.email && formik.errors.email ? true : false}>
                                <FormLabel htmlFor="email">Email Address</FormLabel>
                                <Input 
                                    id="email"
                                    name="email"
                                    type="email"
                                    {...formik.getFieldProps("email")}
                                />
                                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="type">Type of enquiry</FormLabel>
                                <Select id="type" name="type" {...formik.getFieldProps("type")}>
                                    <option value={"hireMe"}>Freelance project proposal</option>
                                    <option value={"openSource"}>
                                        Open source consultancy session
                                    </option>
                                    <option value={"cooperation"}>Project cooperation</option>
                                    <option value={"training"}>Training and Education Consulting</option>
                                    <option value={"other"}>Other</option>
                                </Select>
                            </FormControl>
                            <FormControl isInvalid={formik.touched.comment && formik.errors.comment ? true : false}>
                                <FormLabel htmlFor="comment">Your message</FormLabel>
                                <Textarea
                                    id="comment"
                                    name="comment"
                                    height={250}
                                    {...formik.getFieldProps("comment")}
                                />
                                <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
                            </FormControl>
                            <Button 
                                type="submit"
                                colorScheme="gray"
                                width={"full"}
                                isLoading={isLoading}
                            >
                                Submit
                            </Button>
                        </VStack>
                    </form>
                </Box>
            </VStack>
        </FullScreenSection>
    );
};

export default ContactMeSection;